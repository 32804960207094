<template>
  <el-dialog
    class="warehouse-page fullScreenDialog"
    :title="'上架商品'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    v-if="visible"
    append-to-body
    width="100%"
  >
    <div class="main-container">
      <div class="aside-wrap">
        <h5 class="nameTilCount">
          所有商品
          <div>{{ this.dataList.length }}</div>
        </h5>
        <el-tree
          class="filter-tree"
          height="calc(100vh - 210px)"
          :data="dataList"
          @node-click="handleNodeClick"
          label-width="120px"
        >
        </el-tree>
      </div>

      <div class="aside-wrap">
        <h5 class="nameTilCount">
          商品
          <div>{{ this.defaultList.length }}/{{ this.detailList.length }}</div>
          <el-button type="text" @click="allGoods()" class="rightButton"
            >全选</el-button
          >
        </h5>
        <div>{{ dataForm.goodsName }}</div>
        <el-tree
          class="filter-tree2"
          height="calc(100vh - 210px)"
          :data="detailList"
          show-checkbox
          ref="tree"
          label-width="120px"
          node-key="value"
          @check-change="handleCheckChagne"
        >
        </el-tree>
      </div>

      <div class="main-wrap">
        <h5 class="nameTilCount">
          上架商品
          <div>{{ this.onShelvesList.length }}</div>
          <el-button type="text" @click="clear()" class="rightButton"
            >清空</el-button
          >
        </h5>

        <el-tree
          class="filter-tree"
          height="calc(100vh - 210px)"
          :data="onShelvesList"
          label-width="120px"
          default-expand-all
          :expand-on-click-node="false"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ node.data.name }}</span>
            <el-button
              type="text"
              size="mini"
              @click="() => remove(node, data)"
            >
              删除
            </el-button>
          </span>
        </el-tree>
      </div>
    </div>

    <div class="btn-wrap" slot="footer">
      <el-button @click="close()">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </div>
  </el-dialog>
</template>

<script setup>
import { dialogMixin, fileMixin, normal } from '@/mixins';
export default {
  mixins: [dialogMixin, fileMixin, normal],
  data() {
    return {
      disabled: false,
      visible: false,
      dataList: [],
      detailList: [],
      onShelvesList: [],
      goodsLength: 0,
      defaultList: [],
      dataForm: {
        orgId: '',
        orgCompanyIds: '',
        categoryId: '',
        packageId: '',
        goodsName: '',
        packageIdAndGoodsIds: [],
      },
    };
  },
  created() {},
  methods: {
    init(orgId, orgCompanyId) {
      this.dataForm.orgId = orgId;
      this.dataForm.orgCompanyIds = orgCompanyId;
      this.visible = true;
      this.detailList = [];
      this.dataList = [];
      this.onShelvesList = [];
      this.dataForm.goodsName = null;
      this.dataForm.packageIdAndGoodsIds = [];
      this.$nextTick(() => {
        this.$http({
          url: '/tc/orgcompanygoods/on-shelves',
          method: 'get',
          params: {
            orgId: this.dataForm.orgId,
            orgCompanyIds: this.dataForm.orgCompanyIds,
          },
        }).then(({ data }) => {
          if (data && data.status === 0) {
            this.dataList = data.data.options[0].leftOptions;
            this.onShelvesList = data.data.options[0].children
              .filter((item) => item.name)
              .map((item) => {
                return item;
              });
            this.dataForm.packageIdAndGoodsIds = this.onShelvesList.map(
              (item) => item.value.toString(),
            );
            this.$refs.tree.setCheckedKeys(this.dataForm.packageIdAndGoodsIds);
          } else {
            this.dataList = [];
            this.onShelvesList = [];
          }
        });
      });
    },
    handleNodeClick(data) {
      this.dataForm.categoryId = data.categoryId;
      this.dataForm.packageId = data.packageId;
      if (data.categoryId || data.packageId) {
        this.$http({
          url: '/tc/orgcompanygoods/on-shelves',
          method: 'get',
          params: {
            orgId: this.dataForm.orgId,
            packageId: this.dataForm.packageId,
            categoryId: this.dataForm.categoryId,
          },
        }).then(({ data }) => {
          if (data && data.status === 0) {
            this.detailList = data.data.options[0].children;
            this.dataForm.goodsName = data.data.options[0].label;
            this.$nextTick(() => {
              this.goodsLength = this.$refs.tree.getCheckedKeys().length;
              this.defaultList = this.detailList.map((item) => item.value);
            });
          } else {
            this.detailList = [];
          }
        });
      }
    },
    handleCheckChagne(data, checked, indeterminate) {
      if (checked) {
        this.onShelvesList.push({
          value: data.value.toString(),
          name: data.name,
        });
        this.dataForm.packageIdAndGoodsIds.push(data.value.toString());
      } else {
        let deleteIndex = this.onShelvesList.findIndex(
          (item) => item.value === data.value.toString(),
        );
        let deleteIndex1 = this.dataForm.packageIdAndGoodsIds.findIndex(
          (item) => item === data.value.toString(),
        );
        if (deleteIndex !== -1) {
          this.onShelvesList.splice(deleteIndex, 1);
        }
        if (deleteIndex1 !== -1) {
          this.dataForm.packageIdAndGoodsIds.splice(deleteIndex1, 1);
        }
      }
      this.$nextTick(() => {
        this.goodsLength = this.$refs.tree.getCheckedKeys().length;
      });
    },
    remove(node, data) {
      const index = this.dataForm.packageIdAndGoodsIds.findIndex(
        (item) => item === data.value.toString(),
      );
      this.dataForm.packageIdAndGoodsIds.splice(index, 1);
      const index1 = this.onShelvesList.findIndex(
        (item) => item.value === data.value.toString(),
      );
      this.onShelvesList.splice(index1, 1);
      this.$refs.tree.setCheckedKeys(this.dataForm.packageIdAndGoodsIds);
    },
    clear() {
      this.onShelvesList = [];
      this.dataForm.packageIdAndGoodsIds = [];
      this.$refs.tree.setCheckedKeys(this.dataForm.packageIdAndGoodsIds);
    },
    allGoods() {
      if (
        this.defaultList.toString() ===
        this.$refs.tree.getCheckedKeys().toString()
      ) {
        this.$refs.tree.setCheckedKeys([]);
      } else {
        this.$refs.tree.setCheckedKeys(this.defaultList);
      }
    },
    close() {
      // this.$nextTick(() => {
      //   this.detailList = [];
      //   this.dataList = [];
      //   this.onShelvesList = []
      //   this.dataForm.goodsName = null
      //   this.dataForm.packageIdAndGoodsIds = []
      // })
      this.visible = false;
    },
    // 表单提交
    dataFormSubmit() {
      this.$http({
        url: `/tc/orgcompanygoods/update-shelves`,
        method: 'post',
        data: {
          orgCompanyIds:
            this.dataForm.orgCompanyIds.length > 1
              ? this.dataForm.orgCompanyIds
                  .split(',')
                  .map((item) => parseInt(item))
              : [this.dataForm.orgCompanyIds],
          packageIdAndGoodsIds: this.dataForm.packageIdAndGoodsIds,
        },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1500,
          });
          this.visible = false;
          this.$emit('success');
        } else if (data && data.status === 422) {
          this.$message({
            type: 'error',
            duration: 1500,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.filter-tree {
  height: calc(100vh - 220px);
  overflow-x: hidden;
  overflow-y: auto;
}
.filter-tree2 {
  height: calc(100vh - 250px);
  overflow-x: hidden;
  overflow-y: auto;
}
.rightButton {
  padding: 0 10px;
  float: right;
}
.showName {
  font-size: 13px;
  color: #000;
  background-color: #cdcdcd;
  padding: 4px 8px;
}

.nameTilCount {
  position: relative;
  float: left;
  font-size: 14px;
  div {
    display: inline-block;
    color: #f00;
  }
}
</style>
